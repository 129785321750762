import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import conversationService from "./conversationService.js";

const initialState = {
  conversation: [],
  statusGetWebsite: "idle",
  statusGetDepartments: "idle",
  departments: []
};

// Get website by user
export const getWebsite = createAsyncThunk(
  "conversation/getWebsite",
  async (_, thunkAPI) => {
    try {
      return await conversationService.getWebsite(window.WEBSITE_ID);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
// CreateMessage user
export const getConversation = createAsyncThunk(
  "conversation/getConversation",
  async (conversationId, thunkAPI) => {
    try {
      return await conversationService.getConversation(conversationId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get current user's old conversation

export const getOldConversation = createAsyncThunk(
  "/getOldConversation",

  async (data, thunkAPI) => {
    try {
      return await conversationService.getOldConversation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Assign conversation to a specific department

export const assignDepartment = createAsyncThunk(
  "/assignDepartment",
  async (data, thunkAPI) => {
    try {
      return await conversationService.assignDepartment(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createSession = createAsyncThunk(
  "/createSession",
  async (data, thunkAPI) => {
    try {
      return await conversationService.createSession(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSession = createAsyncThunk(
  "/updateSession",
  async (data, thunkAPI) => {
    try {
      return await conversationService.updateSession(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const transfer = createAsyncThunk(
  "/transfer",
  async (data, thunkAPI) => {
    try {
      return await conversationService.transfer(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// conversation rating
export const conversationRating = createAsyncThunk(
  "conversation/conversationRating",
  async (data, thunkAPI) => {
    try {
      return await conversationService.conversationRating(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    incrementUnreadMessages: (state) => {
      state.conversation = {
        ...state.conversation,
        unread: state.conversation?.unread + 1,
      };
    },
    seenFromOperator: (state) => {
      state.conversation = { ...state.conversation, unread: 0 };
    },
    setConversation: (state, action) => {
      state.conversation = action?.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.conversation = action.payload;
      })
      .addCase(getConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getWebsite.pending, (state) => {
        state.statusGetWebsite = "loading";
      })
      .addCase(getWebsite.fulfilled, (state) => {
        state.statusGetWebsite = "success";
      })
      .addCase(getWebsite.rejected, (state) => {
        state.statusGetWebsite = "failed";
      })
  },
});

export const { reset, incrementUnreadMessages, seenFromOperator, setConversation } =
  conversationSlice.actions;
export default conversationSlice.reducer;
