import React, { useEffect, useState } from "react";
import { calculateDate } from "../../utils/calculateDate.js";
import StyledBadge from "../StyledBadge/index.jsx";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ToolTip from "../ToolTip/index.jsx";
import { useSelector } from "react-redux";
import MessageSeen from "../../assets/icons/seen.svg";
import MessageSent from "../../assets/icons/sent.svg";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import FileMessage from "../FileMessage/FileMessage.jsx";
import { Avatar } from "@mui/material";
import { saveAs } from "file-saver";
import MarkdownIt from 'markdown-it';
import { emitEvent } from "../../socket/socket.js";
import ArrowRight from '@mui/icons-material/NavigateNext';
export default function Message({
  type,
  image,
  createdAt,
  content,
  status,
  sameMessage,
  SvgIcon,
  file,
  name,
  isSent,
  edited,
  isSuccess,
  id,
  filetype,
  errorMsg,
  msg,
  sendHandler,
  handleclick,
  setUserData,
  userData,
  handleSelectChange,
  myMeta,
  color,
  language,
  setConsultAI,
  setSatisfied,
  setConsultOperator,
  AILastMessage,
  pass,
  showMsg,
  setShowMsg,
  setPass,
}) {
  const [selectedChoice, setSelectedChoice] = useState(
    myMeta && myMeta[msg?.label]
  );

  const md = new MarkdownIt();
  const preprocessText = (text) => {
    return text.replace(/(^|\s)(\d\.)/g, '\n$2 ');
  };
  const { isLoading } = useSelector((state) => state.messages);
  const { conversation } = useSelector((state) => state.conversation);
  const saveFile = (file) => {
    saveAs(file);
  };
  const parseHtml = (htmlString) => {
    const replacedHtmlString = htmlString?.replace(
      /<img.*?src="(.*?)"[^\>]+>/g,
      "+?+?+$1+?+?+"
    );

    return replacedHtmlString;
  };

  const isIframe = (htmlString) => {
    const replacedHtmlString = htmlString?.replace(
      /<iframe.*?src="(.*?)"[^\>]+>/g,
      "#?#?#$1#?#?#"
    );

    return replacedHtmlString;
  };

  const linkRenderer = (string) => {
    const linkExp = new RegExp(
      "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return (
      <>
        {string.split(" ").map((part, k) => {
          if (part.startsWith("www.")) {
            part = "http://" + part;
          }
          if (part.match(linkExp)) {
            return (
              <React.Fragment key={k}>
                <a
                  href={part}
                  onFocus={(e) => {
                    e.stopPropagation();
                  }}
                  target="_blank"
                  rel="noreferrer"
                  className="taki-chat-text-link"
                >
                  {" " + part + " "}
                </a>
              </React.Fragment>
            );
          } else {
            return " " + parseHtml(part) + " ";
          }
        })}
      </>
    );
  };
  const breakLinerOperator = (string) => {
    return (
      <div
        dir="auto"
        className={msg?.from !== "BOT" ? "message-content" : ""}
        dangerouslySetInnerHTML={{
          __html: string,
        }}
      />
    );
  };
  const [messageDate, setMessageDate] = useState(calculateDate(createdAt));
  const { messages } = useSelector((state) => state.messages);
  function isImage(url) {
    return /^https?:\/\/.+\.(jpg|jpeg|png|webp|jfif|pjpeg|pjp|avif|gif|svg)$/.test(
      url
    );
  }
  useEffect(() => {
    let interval = setInterval(() => {
      setMessageDate(calculateDate(createdAt));
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  if (type === "OPERATOR") {
    return (
      <div className="message-operator-container">
        {sameMessage === false && (
          <div className="message-operator-wrapper">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={""}
              status={SvgIcon ? "online" : status}
            >
              <div className="message-operator-img">
                {
                  <>
                    {SvgIcon ? (
                      <img src={SvgIcon} />
                    ) : (
                      <Avatar
                        style={{ width: "100%", height: "100%" }}
                        src={image}
                      />
                    )}
                  </>
                }
              </div>
            </StyledBadge>
            <ToolTip
              text={name}
              style={{ top: "0", left: "0", transform: "translateY(-100%)" }}
            />
          </div>
        )}
        <div
          className={
            sameMessage === false
              ? `${file
                ? "message-operator message-operator-image "
                : `message-operator ${msg?.from === "BOT" ? "bot-message"
                  : msg?.from == "AI" ? "ai-message" : ""} `
              }`
              : `${file
                ? "message-operator mgl40  message-operator-image "
                : `message-operator ${msg?.from === "BOT" ? "bot-message"
                  : msg?.from == "AI" ? "ai-message" : ""
                } mgl40`
              }
        `
          }
        >
          <ToolTip text={messageDate} />
          {file && filetype?.includes("image") ? (
            <div className="taki-chat-file-content-container" dir="auto">
              <div className="taki-chat-file">
                {edited && <p className="edited">Edited</p>}
                <FileMessage file={file} fileType={"image"} />
              </div>
              <div className="taki-chat-image-content">
                {content && (
                  <p className="taki-chat-image-content-operator-msg">
                    {parseHtml(content).includes("+?+?+")
                      ? parseHtml(content)
                        .split("+?+?+")
                        .map((item, idx) => {
                          if (isImage(item)) {
                            return (
                              <FileMessage
                                className="message-file"
                                file={item}
                                fileType={"image"}
                                key={idx}
                              />
                            );
                          } else {
                            return (
                              <div key={idx}>{breakLinerOperator(item)}</div>
                            );
                          }
                        })
                      : isIframe(content).includes("#?#?#")
                        ? isIframe(content)
                          .split("#?#?#")
                          .map((item, idx) => {
                            if (item.includes("https")) {
                              return (
                                <FileMessage
                                  className="message-file"
                                  file={item}
                                  fileType={"iframe"}
                                  key={idx}
                                />
                              );
                            } else {
                              return (
                                <div key={idx}>{breakLinerOperator(item)}</div>
                              );
                            }
                          })
                        : breakLinerOperator(content)}
                  </p>
                )}
              </div>
            </div>
          ) : file && filetype?.includes("pdf") ? (
            <div className="taki-chat-file-content-container" dir="auto">
              {content && (
                <p className="taki-chat-file-content-operator-msg">
                  {parseHtml(content).includes("+?+?+")
                    ? parseHtml(content)
                      .split("+?+?+")
                      .map((item, idx) => {
                        if (isImage(item)) {
                          return (
                            <FileMessage
                              className="message-file"
                              file={item}
                              fileType={"image"}
                              key={idx}
                            />
                          );
                        } else {
                          return (
                            <div key={idx}>{breakLinerOperator(item)}</div>
                          );
                        }
                      })
                    : isIframe(content).includes("#?#?#")
                      ? isIframe(content)
                        .split("#?#?#")
                        .map((item, idx) => {
                          if (item.includes("https")) {
                            return (
                              <FileMessage
                                className="message-file"
                                file={item}
                                fileType={"iframe"}
                                key={idx}
                              />
                            );
                          } else {
                            return (
                              <div key={idx}>{breakLinerOperator(item)}</div>
                            );
                          }
                        })
                      : breakLinerOperator(content)}
                </p>
              )}
              <div className="taki-chat-pdf-from-operator">
                <div className="taki-chat-pdf-container">
                  <PictureAsPdfOutlinedIcon />
                  <span>PDF</span>
                </div>
                <FileDownloadOutlinedIcon
                  onClick={() => saveFile(file)}
                  className="download-file-icon"
                />
              </div>
            </div>
          ) : file &&
            filetype?.includes("video") &&
            filetype.includes("wmv") ? (
            <div className="taki-chat-video-wmv">
              {content && (
                <p className="taki-chat-file-content-operator-msg">
                  {parseHtml(content).includes("+?+?+")
                    ? parseHtml(content)
                      .split("+?+?+")
                      .map((item, idx) => {
                        if (isImage(item)) {
                          return (
                            <FileMessage
                              className="message-file"
                              file={item}
                              fileType={"image"}
                              key={idx}
                            />
                          );
                        } else {
                          return (
                            <div key={idx}>{breakLinerOperator(item)}</div>
                          );
                        }
                      })
                    : isIframe(content).includes("#?#?#")
                      ? isIframe(content)
                        .split("#?#?#")
                        .map((item, idx) => {
                          if (item.includes("https")) {
                            return (
                              <FileMessage
                                className="message-file"
                                file={item}
                                fileType={"iframe"}
                                key={idx}
                              />
                            );
                          } else {
                            return (
                              <div key={idx}>{breakLinerOperator(item)}</div>
                            );
                          }
                        })
                      : breakLinerOperator(content)}
                </p>
              )}
              <div>
                <VideocamOutlinedIcon />

                <span>Video</span>
                <FileDownloadOutlinedIcon
                  onClick={() => saveFile(file)}
                  className="download-file-icon"
                />
              </div>
            </div>
          ) : file && filetype?.includes("audio") ? (
            <div className="taki-chat-file-content-container" dir="auto">
              {content && (
                <p className="taki-chat-file-content-operator-msg">
                  {parseHtml(content).includes("+?+?+")
                    ? parseHtml(content)
                      .split("+?+?+")
                      .map((item, idx) => {
                        if (isImage(item)) {
                          return (
                            <FileMessage
                              className="message-file"
                              file={item}
                              fileType={"image"}
                              key={idx}
                            />
                          );
                        } else {
                          return (
                            <div key={idx}>{breakLinerOperator(item)}</div>
                          );
                        }
                      })
                    : isIframe(content).includes("#?#?#")
                      ? isIframe(content)
                        .split("#?#?#")
                        .map((item, idx) => {
                          if (item.includes("https")) {
                            return (
                              <FileMessage
                                className="message-file"
                                file={item}
                                fileType={"iframe"}
                                key={idx}
                              />
                            );
                          } else {
                            return (
                              <div key={idx}>{breakLinerOperator(item)}</div>
                            );
                          }
                        })
                      : breakLinerOperator(content)}
                </p>
              )}
              <audio controls>
                <source src={file} type="audio/mpeg" />
              </audio>
            </div>
          ) : file && filetype?.includes("video") ? (
            <div className="taki-chat-file-content-container">
              <p className="taki-chat-file-content-operator-msg" dir="auto">
                {content && parseHtml(content).includes("+?+?+")
                  ? parseHtml(content)
                    .split("+?+?+")
                    .map((item, idx) => {
                      if (isImage(item)) {
                        return (
                          <FileMessage
                            className="message-file"
                            file={item}
                            fileType={"image"}
                            key={idx}
                          />
                        );
                      } else {
                        return (
                          <div key={idx}>{breakLinerOperator(item)}</div>
                        );
                      }
                    })
                  : isIframe(content).includes("#?#?#")
                    ? isIframe(content)
                      .split("#?#?#")
                      .map((item, idx) => {
                        if (item.includes("https")) {
                          return (
                            <FileMessage
                              className="message-file"
                              file={item}
                              fileType={"iframe"}
                              key={idx}
                            />
                          );
                        } else {
                          return (
                            <div key={idx}>{breakLinerOperator(item)}</div>
                          );
                        }
                      })
                    : breakLinerOperator(content)}
              </p>
              <div className="taki-chat-video-file">
                <video width="100%" height="200" controls>
                  <source src={file} type="video/mp4"></source>
                </video>
              </div>
            </div>
          ) : (file && filetype?.includes("wordprocessingml")) ||
            filetype?.includes("msword") ? (
            <div className="taki-chat-video-wmv">
              {content && (
                <p className="taki-chat-file-content-operator-msg">
                  {parseHtml(content).includes("+?+?+")
                    ? parseHtml(content)
                      .split("+?+?+")
                      .map((item, idx) => {
                        if (isImage(item)) {
                          return (
                            <FileMessage
                              className="message-file"
                              file={item}
                              fileType={"image"}
                              key={idx}
                            />
                          );
                        } else {
                          return (
                            <div key={idx}>{breakLinerOperator(item)}</div>
                          );
                        }
                      })
                    : isIframe(content).includes("#?#?#")
                      ? isIframe(content)
                        .split("#?#?#")
                        .map((item, idx) => {
                          if (item.includes("https")) {
                            return (
                              <FileMessage
                                className="message-file"
                                file={item}
                                fileType={"iframe"}
                                key={idx}
                              />
                            );
                          } else {
                            return (
                              <div key={idx}>{breakLinerOperator(item)}</div>
                            );
                          }
                        })
                      : breakLinerOperator(content)}
                </p>
              )}
              <div>
                <ArticleOutlinedIcon />
                <span>Word document</span>
                <FileDownloadOutlinedIcon
                  onClick={() => saveFile(file)}
                  className="download-file-icon"
                />
              </div>
            </div>
          ) : (file && filetype?.includes("presentationml")) ||
            filetype?.includes("powerpoint") ? (
            <div className="taki-chat-video-wmv">
              {content && (
                <p className="taki-chat-file-content-operator-msg">
                  {parseHtml(content).includes("+?+?+")
                    ? parseHtml(content)
                      .split("+?+?+")
                      .map((item, idx) => {
                        if (isImage(item)) {
                          return (
                            <FileMessage
                              className="message-file"
                              file={item}
                              fileType={"image"}
                              key={idx}
                            />
                          );
                        } else {
                          return (
                            <div key={idx}>{breakLinerOperator(item)}</div>
                          );
                        }
                      })
                    : isIframe(content).includes("#?#?#")
                      ? isIframe(content)
                        .split("#?#?#")
                        .map((item, idx) => {
                          if (item.includes("https")) {
                            return (
                              <FileMessage
                                className="message-file"
                                file={item}
                                fileType={"iframe"}
                                key={idx}
                              />
                            );
                          } else {
                            return (
                              <div key={idx}>{breakLinerOperator(item)}</div>
                            );
                          }
                        })
                      : breakLinerOperator(content)}
                </p>
              )}
              <div>
                <ArticleOutlinedIcon />
                <span>PowerPoint file</span>
                <FileDownloadOutlinedIcon
                  onClick={() => saveFile(file)}
                  className="download-file-icon"
                />
              </div>
            </div>
          ) : (
            <div
              dir="auto"
              className={
                msg && msg?.from === "BOT"
                  ? "message-content-operator-msg bot-msg-content"
                  : "message-content-operator-msg"
              }
            >
              {edited && <p className="edited">Edited</p>}
              {content && parseHtml(content).includes("+?+?+")
                ? parseHtml(content)
                  .split("+?+?+")
                  .map((item, idx) => {
                    if (isImage(item)) {
                      return (
                        <FileMessage
                          className="message-file"
                          file={item}
                          fileType={"image"}
                          key={idx}
                        />
                      );
                    } else {
                      return <div key={idx}>{breakLinerOperator(item)}</div>;
                    }
                  })
                : content && isIframe(content).includes("#?#?#")
                  ? isIframe(content)
                    .split("#?#?#")
                    .map((item, idx) => {
                      if (item.includes("https")) {
                        return (
                          <FileMessage
                            className="message-file"
                            file={item}
                            fileType={"iframe"}
                            key={idx}
                          />
                        );
                      } else {
                        return <div key={idx}>{breakLinerOperator(item)}</div>;
                      }
                    })
                  : msg?.from === "BOT" && msg?.translations?.length > 0 ? (() => {
                    const m = msg?.translations?.find(
                      (msg) => msg.language == language
                    );
                    if (m) {
                      return breakLinerOperator(m?.content);
                    } else {
                      return breakLinerOperator(content);
                    }
                  })()
                    : msg?.from == "AI" ? breakLinerOperator(md.render(preprocessText(content))) : breakLinerOperator(content)}
              {!content && "This message has been deleted."}
            </div>
          )}
          {(!msg?.type || msg?.type?.toLowerCase() == "input") && (
            <div
              className={
                msg && ["AI", "BOT"]?.includes(msg?.from)
                  ? "msg-content bot-msg"
                  : "msg-content"
              }
            >
              {msg?.content &&
                msg?.from === "BOT" &&
                (!msg?.type || msg?.type?.toLowerCase() === "input") && (
                  <div key={msg?._id} style={{ display: "flex", gap: "12px" }}>
                    <input
                      type={
                        msg?.label?.includes("email") ||
                          msg?.label?.includes("mail")
                          ? "email"
                          : "text"
                      }
                      className="bot-msg-input"
                      style={color && { borderColor: color }}
                      onChange={(e) => setUserData(e.target.value)}
                      id={msg?._id}
                      key={msg?._id}
                      placeholder={myMeta && myMeta[msg?.label]}
                      disabled={
                        new Date(msg?.createdAt) <=
                        new Date(
                          parseInt(localStorage.getItem("started-at"), 10)
                        )
                      }
                    />
                    <button
                      id={`${msg?._id}btn`}
                      className="bot-msg-button"
                      onClick={(e) =>
                        sendHandler(
                          e,
                          "notadd",
                          "dispatch",
                          {
                            msg,
                            userData,
                            work: true,
                          },
                          true
                        )
                      }
                      style={color && { backgroundColor: color }}
                      disabled={
                        new Date(msg?.createdAt) <=
                        new Date(
                          parseInt(localStorage.getItem("started-at"), 10)
                        )
                      }
                    >
                      OK
                    </button>
                  </div>
                )}

            </div>
          )}
          {pass == 3 && msg?.content &&
            msg?.from === "AI" &&
            AILastMessage &&
            (
              <div key={msg?.id} >
                <p> Voulez vous être recontacté par un conseiller? </p>
                <div style={{ display: 'flex', gap: '10px', float: 'left' }} >
                  <button
                    id={`btn1`}
                    className="bot-msg-button"
                    onClick={(e) => {
                      emitEvent('PASS_ME_TO_OPERATOR', {
                        websiteID: window.WEBSITE_ID,
                        conversation: conversation?._id
                      })
                      setConsultOperator(true);
                      setPass(0)
                      setConsultAI(false);
                    }
                    }
                    style={color && { backgroundColor: color }}
                  >
                    Oui
                  </button>
                  <button
                    id={`btn2`}
                    className="bot-msg-button"
                    onClick={(e) => {
                      setPass(0)
                    }
                    }
                    style={color && { backgroundColor: color }}
                  >
                    Non
                  </button>
                </div>
              </div>
            )}
          {msg?.type?.toLowerCase().includes("select") &&
            msg?.choices?.length > 0 && (
              <select
                id={"myselect" + msg?._id}
                onChange={(e) => handleSelectChange(e, msg)}
                disabled={
                  new Date(msg?.createdAt) <=
                  new Date(parseInt(localStorage.getItem("started-at"), 10))
                }
                className="bot-msg-select"
                style={color && { borderColor: color }}
              >
                <option>Select a value</option>
                {msg?.choices?.map((submsg, i) => !submsg?.draft && (
                  <option
                    key={submsg?._id}
                    value={JSON.stringify(submsg)}
                    selected={
                      myMeta && myMeta[msg?.label] === submsg?.content && true
                    }
                  >
                    {submsg?.translations?.length > 0
                      ? (() => {
                        const userLanguage =
                          navigator.language || navigator.userLanguage;
                        const languageCode = userLanguage.split("-")[0];
                        const m = submsg?.translations?.find(
                          (msg) => msg.language == languageCode
                        );
                        if (m) {
                          return breakLinerOperator(m?.content);
                        } else {
                          return breakLinerOperator(submsg?.content);
                        }
                      })()
                      : submsg?.content}
                  </option>
                ))}
              </select>
            )}
          {msg?.type?.toLowerCase().includes("checkbox") &&
            msg?.choices?.length > 0 && (
              <div className="choices-container" key={msg?._id}>
                <p>Veuillez cliquer sur l'une des proposotions suivantes:</p>
                {msg?.choices?.map((submsg, i) => {
                  if (!submsg?.draft) {
                    const isSelected = selectedChoice === submsg?.content;
                    return (
                      <div
                        className={`single-choice ${isSelected ? 'selected' : ''}`}
                        key={submsg?._id}
                        onClick={(e) => {
                          setSelectedChoice(submsg?.content);
                          handleclick(submsg, e.currentTarget, "checkbox", msg);
                        }}
                        style={{
                          backgroundColor: isSelected
                            ? color || "rgba(80, 50, 250, 0.5)"
                            : "transparent",
                          borderColor: isSelected
                            ? color || "rgba(80, 50, 250, 0.5)"
                            : "initial",
                          "--dynamic-color": color || "rgba(80, 50, 250, 0.9411764706)",
                        }}
                      >
                        <div className="bot-msg-check">
                          <div className="bot-msg-checkbox-container">
                            <label
                              className="bot-msg-checkbox-label"
                              style={{
                                "--dynamic-color":
                                  color || "rgba(80, 50, 250, 0.9411764706)",
                              }}
                            >
                              <span
                                className="bot-msg-mark"
                                style={color && { borderColor: color }}
                              >  <ArrowRight /> </span>
                            </label>
                          </div>
                        </div>
                        <p style={{ fontWeight: 'bold', fontSize: '14px' }}>
                          {submsg?.translations?.length > 0
                            ? (() => {
                              const userLanguage =
                                navigator.language || navigator.userLanguage;
                              const languageCode = userLanguage.split("-")[0];
                              const m = submsg?.translations?.find(
                                (msg) => msg.language == languageCode
                              );
                              if (m) {
                                return breakLinerOperator(m?.content);
                              } else {
                                return breakLinerOperator(submsg?.content);
                              }
                            })()
                            : submsg?.content}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            )

          }
          {showMsg && AILastMessage && msg?.from == "BOT" &&
            < div >
              <p> Voulez vous être recontacté par un conseiller? </p>
              <div style={{ display: 'flex', gap: '10px', float: 'left' }} >
                <button
                  id={`${msg?.id}btn1`}
                  className="bot-msg-button"
                  onClick={(e) => {
                    emitEvent('PASS_ME_TO_OPERATOR', {
                      websiteID: window.WEBSITE_ID,
                      conversation: conversation?._id
                    })
                    setConsultAI(false);
                    setSatisfied(true);
                    setConsultOperator(true);
                    setShowMsg(false)
                  }
                  }
                  style={color && { backgroundColor: color }}
                >
                  Oui
                </button>
                <button
                  id={`${msg?.id}btn2`}
                  className="bot-msg-button"
                  onClick={(e) => {
                    setShowMsg(false)
                  }
                  }
                  style={color && { backgroundColor: color }}
                >
                  Non
                </button>
              </div>
            </div>
          }
          {msg?.type === "radio" && msg?.choices?.length > 0 && (
            <div className="choices-container" key={msg?._id}>
              {msg?.choices?.map((submsg, i) => {
                if (!submsg?.draft) {
                  return (
                    <div className="single-choice">
                      <div className="bot-msg-check">
                        <div className="bot-msg-checkbox-container">
                          <label className="bot-msg-checkbox-label">
                            <input
                              type="radio"
                              name="progress"
                              id={`progress${i}`}
                              checked={
                                myMeta &&
                                myMeta[msg?.label] === submsg?.content &&
                                true
                              }
                              disabled={
                                new Date(submsg?.createdAt) <=
                                new Date(
                                  parseInt(localStorage.getItem("started-at"), 10)
                                )
                              }
                              onClick={(e) => {
                                handleclick(submsg, e.target, "checkbox");
                              }}
                              key={submsg?._id}
                            />
                            <span className="bot-msg-mark"></span>
                          </label>
                        </div>
                      </div>
                      <p className="bot-msg-content">
                        {submsg?.translations?.length > 0
                          ? (() => {
                            const userLanguage =
                              navigator.language || navigator.userLanguage;
                            const languageCode = userLanguage.split("-")[0];
                            const m = submsg?.translations?.find(
                              (msg) => msg.language == languageCode
                            );
                            if (m) {
                              return breakLinerOperator(m?.content);
                            } else {
                              return breakLinerOperator(submsg?.content);
                            }
                          })()
                          : submsg?.content}
                      </p>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div >
    );
  }
  return (
    <div className="clientmsg-wrapper">
      <div
        className={
          file ? "message-client message-client-image" : "message-client"
        }
        style={color && { backgroundColor: color }}
      >
        <ToolTip text={messageDate} />
        {file && filetype?.includes("image") ? (
          <>
            <div className="taki-chat-file">
              {edited && <p className="edited">Edited</p>}
              <div className="taki-chat-file-content-client-msg" dir="auto">
                {content && breakLinerOperator(content)}
              </div>
            </div>
            <FileMessage file={file} fileType={"image"} />
          </>
        ) : file && filetype?.includes("pdf") ? (
          <div className="taki-chat-pdf">
            <div className="taki-chat-pdf-container">
              <PictureAsPdfOutlinedIcon />
              <span>PDF</span>
              <FileDownloadOutlinedIcon
                onClick={() => saveFile(file)}
                className="download-file-icon"
              />
            </div>
            <div className="taki-chat-file-content-client-msg" dir="auto">
              {content && breakLinerOperator(content)}
            </div>
          </div>
        ) : file && filetype?.includes("video") && filetype?.includes("wmv") ? (
          <div className="taki-chat-video-wmv">
            <div>
              <VideocamOutlinedIcon />

              <span>Video</span>
              <FileDownloadOutlinedIcon
                onClick={() => saveFile(file)}
                className="download-file-icon"
              />
              <div className="taki-chat-file-content-client-msg" dir="auto">
                {content && breakLinerOperator(content)}
              </div>
            </div>
          </div>
        ) : file && filetype?.includes("video") ? (
          <>
            <div className="video-container">
              <video width="100%" height="200px" controls>
                <source src={file} type="video/mp4"></source>
              </video>
              <div className="taki-chat-file-content-client-msg" dir="auto">
                {content && breakLinerOperator(content)}
              </div>
            </div>
          </>
        ) : file && filetype?.includes("audio") ? (
          <div className="taki-chat-file-content-container">
            <audio controls>
              <source src={file} type="audio/mpeg" />
            </audio>
            <div className="taki-chat-file-content-client-msg" dir="auto">
              {content && breakLinerOperator(content)}
            </div>
          </div>
        ) : (file && filetype?.includes("wordprocessingml")) ||
          filetype?.includes("msword") ? (
          <div className="taki-chat-video-wmv">
            <div>
              <ArticleOutlinedIcon />
              <span>Word document</span>
              <FileDownloadOutlinedIcon
                onClick={() => saveFile(file)}
                className="download-file-icon"
              />
            </div>
            <div className="taki-chat-file-content-client-msg" dir="auto">
              {content && breakLinerOperator(content)}
            </div>
          </div>
        ) : (file && filetype?.includes("presentationml")) ||
          filetype?.includes("powerpoint") ? (
          <div className="taki-chat-video-wmv">
            <div>
              <ArticleOutlinedIcon />
              <span>PowerPoint file</span>
              <FileDownloadOutlinedIcon
                onClick={() => saveFile(file)}
                className="download-file-icon"
              />
            </div>
            <div className="taki-chat-pdf " dir="auto">
              {content && breakLinerOperator(content)}
            </div>
          </div>
        ) : (
          <div dir="auto" className="message-content-client-msg">
            {content && breakLinerOperator(content)}
          </div>
        )}
      </div>
      {!isLoading &&
        id === messages[messages.length - 1]?._id &&
        messages[messages.length - 1]?.from === "CLIENT" &&
        conversation?.unread === 0 && (
          <div className="client-msg-seen-container">
            <img src={MessageSeen} alt='message-seen' />
          </div>
        )}

      {!isLoading &&
        isSuccess &&
        id === messages[messages.length - 1]?._id &&
        messages[messages.length - 1]?.from === "CLIENT" &&
        conversation?.unread !== 0 && (
          <div className="client-msg-sent-container">
            <img src={MessageSent} alt='message-seen' />
          </div>
        )}

      {isSent === false && (
        <div className="not-sent">
          {errorMsg.length > 0 ? errorMsg : "Couldn't send"}
        </div>
      )}
    </div>
  );
}
