export const botMessages = [
    {
        lang: 'en',
        body: "Our support team will contact you as soon as possible."
    },
    {
        lang: 'fr',
        body: "Notre équipe support vous contactera dans les plus brefs délais"
    },
    {
        lang: 'ar',
        body: "سأجعلك على اتصال مع أحد مستشارينا. يرجى الانتظار بضع دقائق."
    },
    {
        lang: 'de',
        body: "Ich werde Sie mit einem unserer Berater in Kontakt bringen."
    },
    {
        lang: 'default',
        body: "Notre équipe support vous contactera dans les plus brefs délais"
    }

]