import React, { useState } from "react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import {
  Popover,
  Box,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { Messaging } from "react-cssfx-loading";
import EmojiPicker, { Categories } from "emoji-picker-react";
import Employee from "../Employee/index.jsx";
import Cookies from "universal-cookie";
import SendIcon from "../../assets/icons/sendIcon.svg";
import { ReactComponent as SmileIcon } from "../../assets/icons/smileIcon.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessageToState,
  changeCreatedByMessagesStatus,
  createMessage,
  createMessageByBot,
  getMessages,
  reset,
  updateMessage,
  deleteMessage,
  seenMessageClient,
  setMsg,
  SeenByClient,
  // addUser,
  sendMeta,
  getQuestionByChoice,
  getQuestionByOrder,
  addUser,
  translate,
  askAI,
  createMessageByAI,
  transcribe,
} from "../../features/message/messageSlice";
import socket, { addEvent, emitEvent } from "../../socket/socket";
import { useEffect } from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { nanoid } from "nanoid";
import Message from "../Messages";
import BotIcon from "../../assets/images/poste.png";
import AIicon from "../../assets/images/poste.png";
import ChatboxLogo from "../../assets/images/image.png";
import {
  changeOperatorStatus,
  getAllOperators,
} from "../../features/operator/operatorSlice";
import RatingModal from "../RatingModal";
import StyledBadge from "../StyledBadge";
import Upload from "../Upload/index.jsx";
import {
  getConversation,
  getOldConversation,
  seenFromOperator,
  getWebsite,
  assignDepartment,
  setConversation,
  createSession,
  transfer,
  updateSession
} from "../../features/conversation/conversationSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { isNotValid } from "../../utils/isNotValidText.js";
import { removeCookiesByName } from "../../utils/removeCookie.js";
import AudioRecorder from "../AudioRecorder/index.js";
import { useAudioRecorder } from "@sarafhbk/react-audio-recorder";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { botMessages } from '../../Locales/botMessages.js'

const ClientChat = () => {
  const [text, setText] = useState("");
  const [open, setIsOpen] = useState(null);
  const [sessionId, setSession] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [isTypingEvent, setIsTypingEvent] = useState(false);
  const [getFile, setGetFile] = useState(null);
  const [botTimer, setBotTimer] = useState(null);
  const [awayTimer, setAwayTimer] = useState(null);
  const [isAway, setIsAway] = useState(false);
  const [pass, setPass] = useState(0);
  const [useAI, setUseAI] = useState(false);
  const [consultAI, setConsultAI] = useState(true);
  const [resolved, setResolved] = useState(false);
  const [receivedOffline, setReceivedOffline] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [counter, setCounter] = useState(0);
  const [sent, setSent] = useState(false);
  const [satisfied, setSatisfied] = useState(false);
  const [consultOperator, setConsultOperator] = useState(false);
  const [openCounter, setOpenCounter] = useState(0);
  const [reached, setReached] = useState(false);
  let sameMessage;
  // const numberRegex = /\d+/g;
  const [todayDate, setTodayDate] = useState(false);
  const [status, setStatus] = useState("online");
  const [conversationId, setConversationId] = useState(null);
  const { conversation, statusGetWebsite } = useSelector(
    (state) => state.conversation
  );

  const userLanguage = navigator.language || navigator.userLanguage;
  const [isRecording, setIsRecording] = useState(false);

  const cookies = new Cookies();
  const [meta, setMeta] = useState(null);
  const [fetched, setFetched] = useState(false);
  //BOT STATES
  const [order, setOrder] = useState(1);
  const [isGoing, setIsGoingAgain] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [language, setLanguage] = useState(userLanguage.split('-')[0])
  const [nextQuestion, setNextQuestion] = useState(null);
  const [choices, setChoices] = useState(null);
  const [nextQuestionChoice, setNextQuestionChoice] = useState(null);
  const [userData, setUserData] = useState("");
  const [modal, setModal] = useState(false);
  const [color, setColor] = useState(null);
  const [skip, setSkip] = useState(false);
  const [transferAI, settransferAI] = useState(false);
  const [transferOperator, settransferOperator] = useState(false);
  const [start, setStart] = useState(true);
  const [startedRecording, setStartedRecording] = useState(false);
  const [d] = useState({});
  const [isTranslated, setIsTranslated] = useState(false);
  const handlePasteImage = (evt) => {
    const clipboardItems = evt.clipboardData.items;
    const items = [].slice.call(clipboardItems).filter(function (item) {
      // Filter the image items only
      return /^image\//.test(item.type);
    });
    if (items.length === 0) {
      return;
    }
    const item = items[0];
    const blob = item.getAsFile();
    let file = new File(
      [blob],

      `Copied image ${Date.now()}`,

      { type: "image/jpeg", lastModified: new Date().getTime() },

      "utf-8"
    );
    setGetFile(file);
  };
  const audio = document?.querySelector("audio");
  let {
    messages,
    isError,
    errorMessage,
    fileLoading,
    messagesTotalPages,
    getMessagesLoading,
    getMessagesSuccess,
  } = useSelector((state) => state.messages);
  const [page, setPage] = useState(1);
  const fetchAllMessages = () => {
    if (conversationId) {
      dispatch(getMessages({ page, conversationId })).then((res) => {
        if (loaded) {
          bot({ messages: res?.payload?.docs }, null, null, true);
          setLoaded(false)
        }
        !res?.error && setPage((prev) => prev + 1);
      });
    }
  };

  // const audio = new Audio(soundFile);
  const { connectedOperators, totalOperators } = useSelector(
    (state) => state.operators
  );
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    if (startedRecording) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setSeconds(0);
    }
  }, [startedRecording]);

  useEffect(() => {
    if (consultOperator) {
      setIsTyping(true);
      setIsLoading(true)
      const content = botMessages?.find((msg) => {
        if (msg?.lang == language) {
          return msg;
        }
        return msg?.lang == "default"
      });
      dispatch(createMessageByAI({
        content: content?.body
      }));
      setTimeout(() => {
        dispatch(
          addMessageToState({
            content: content?.body,
            from: "BOT",
            type: "message",
            createdAt: Date.now(),
          })
        );
        setIsLoading(false);
        setIsTyping(false);
      }
        , 3000);
    }
  }, [consultOperator])

  const [assignedOperator, setNewAssignedOperator] = useState(null);
  const chatBoxBody = document.querySelector(".chat-box-body");
  const dispatch = useDispatch();
  let fakeId = nanoid(5);
  useEffect(() => {
    window.WEBSITE_ID = "450361dd-884b-1a8c-a";
    window.$takiChat = [];
    if (
      cookies.get(`conversationId${window.WEBSITE_ID}`) &&
      (cookies.get(`conversationId${window.WEBSITE_ID}`) == "undefined" ||
        cookies.get(`conversationId${window.WEBSITE_ID}`) == "null" ||
        !cookies
          .get(`conversationId${window.WEBSITE_ID}`)
          ?.match(/^[0-9a-fA-F]{24}$/))
    ) {
      removeCookiesByName(`conversationId${window.WEBSITE_ID}`);
    }

    dispatch(getWebsite()).then((res) => setColor(res.payload?.color));
  }, []);
  useEffect(() => {
    const storedConversationId = cookies.get(
      `conversationId${window.WEBSITE_ID}`
    );
    if (storedConversationId?.match(/^[0-9a-fA-F]{24}$/)) {
      setConversationId(storedConversationId);
    }
    if (localStorage.getItem("ReceivedOffline") === "true") {
      setReceivedOffline(true);
    }
  }, []);

  const formatTime = (totalSeconds) => {
    return new Date(totalSeconds * 1000)
      .toISOString()
      .substr(11, 8);
  };

  useEffect(() => {
    const emitConnectEvent = (newSocket) => {
      if (
        cookies.get(`conversationId${window.WEBSITE_ID}`) &&
        window?.$takiChat?.length == 0
      ) {
        emitEvent("CONNECT", {
          webSiteId: window.WEBSITE_ID,
          SOCKET_ID: newSocket?.id,
          conversationId: cookies.get(`conversationId${window.WEBSITE_ID}`),
        });
      }
      if (window?.$takiChat?.length > 0) {
        emitEvent("CONNECT", {
          webSiteId: window.WEBSITE_ID,
          SOCKET_ID: newSocket?.id,
          conversationId: cookies.get(`conversationId${window.WEBSITE_ID}`),
          id: window.$takiChat[0]?.id,
        });
      }
    };
    socket.on("connect", () => {
      console.log("Connected");
      emitConnectEvent(socket);
    });

    socket.on("disconnect", () => {
      setTimeout(() => {
        if (!socket.connected) {
          emitConnectEvent(socket);
        }
      }, 2000);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const res = await dispatch(getConversation(conversationId));
        if (
          typeof res.payload === "string" &&
          (res.payload.includes("not found") ||
            res.payload.includes("contains an invalid value"))
        ) {
          removeCookiesByName(`conversationId${window.WEBSITE_ID}`);
          return;
        }
        setMeta(res?.payload?.meta);
        setConversationId(res?.payload?._id);
      } catch (error) {
        console.log("error", error);
      }
    };

    const fetchOldConversation = async () => {
      try {
        if (window.$takiChat?.length > 0) {
          const res = await dispatch(
            getOldConversation({ meta: window.$takiChat[0] })
          );
          if (res?.meta?.arg?.meta?.id) {
            setConversationId(
              cookies.get(`conversationId${window.WEBSITE_ID}`)
            );
          }
          dispatch(addUser(window.$takiChat[0]));
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    if (conversationId && messages?.length === 0) {
      fetchConversation();
    }
    if (window.$takiChat?.length > 0) {
      fetchOldConversation();
    }
  }, [conversationId]);

  // SCROLL_TO_BOTTOM_WHEN_OPERATORS_TYPES_ON_FIRST_TIME
  useEffect(() => {
    let timer;
    if (isTyping) {
      chatBoxBody?.scroll({
        top: chatBoxBody?.scrollHeight,
        behavior: "smooth",
      });
    }
    timer = setTimeout(function () {
      setIsTyping(false);
    }, 20000);
    return () => {
      clearTimeout(timer);
    };
  }, [isTyping]);

  useEffect(() => {
    if (open) {
      setReceivedOffline(false);
      localStorage.setItem("ReceivedOffline", false);
      clearTimeout(awayTimer);
      addEvent("MESSAGE_FROM_OPERATOR", (data) => {
        setShowMsg(false);
        setReached(true)
        sessionId && dispatch(updateSession({
          sessionId: sessionId,
          endedAt: new Date(Date.now())
        }))
        if (transferAI) {
          if (!transferOperator) {
            dispatch(transfer({
              conversation: cookies.get(`conversationId${window.WEBSITE_ID}`),
              source: 'AI',
              target: 'OPERATOR'
            }))
            settransferOperator(true)
          }
        }
        dispatch(addMessageToState(data?.lastMessage));
        dispatch(seenMessageClient(data?.lastMessage));
        setNewAssignedOperator(data?.lastMessage?.createdBy);
        dispatch(
          SeenByClient({
            message: data?.lastMessage?._id,
            conversation: data?.lastMessage?.conversation,
          })
        );
        emitEvent("SEEN_FROM_CLIENT", data?.lastMessage);
        setConsultAI(false);
        audio?.play().catch((error) => {
          // Handle the error, if any
          console.error("Error playing audio:", error);
        });
      });

      // RESOLVED_FROM_OPERATOR
      addEvent("RESOLVED_FROM_OPERATOR", () => {
        setResolved(true);
      });
      addEvent("UPDATE_MESSAGE_FROM_OPERATOR", (data) => {
        dispatch(updateMessage(data?.lastMessage));
      });
      addEvent("DELETE_MESSAGE_FROM_OPERATOR", (data) => {
        dispatch(deleteMessage(data?.lastMessage));
      });
      addEvent("IS_TYPING_FROM_OPERATOR", (data) => {
        if (data.isTyping === true) {
          setIsTyping(true);
        } else {
          setIsTyping(false);
        }
      });
      addEvent("SEEN_FROM_OPERATOR", () => {
        dispatch(seenFromOperator());
      });
      // STATUS EVENTS
      addEvent("ACTIVE", (data) => {
        dispatch(
          changeCreatedByMessagesStatus({
            operatorId: data?.operatorId,
            status: "online",
          })
        );
        dispatch(
          changeOperatorStatus({
            operatorId: data?.operatorId,
            status: "online",
          })
        );
      });
      // AWAY EVENT
      addEvent("AWAY_FROM_OPERATOR", (data) => {
        dispatch(
          changeOperatorStatus({
            operatorId: data?.operatorId,
            status: "away",
          })
        );
        dispatch(
          changeCreatedByMessagesStatus({
            operatorId: data?.operatorId,
            status: "away",
          })
        );
      });
      // DISCONNECT EVENT
      addEvent("DISCONNECT_OPERATOR", (data) => {
        if (data?.operatorId) {
          dispatch(
            changeOperatorStatus({
              operatorId: data?.operatorId,
              status: "offline",
            })
          );
          dispatch(
            changeCreatedByMessagesStatus({
              operatorId: data?.operatorId,
              status: "offline",
            })
          );
        }
      });
    } else {
      const newAwayTimer = setTimeout(
        () => {
          emitEvent("AWAY_FROM_CHAT", {
            webSiteId: window.WEBSITE_ID,
            conversationId,
          });
          setIsAway(true);
        },
        10000
        // * 60 * 5
      );
      addEvent("MESSAGE_FROM_OPERATOR", (data) => {
        setReceivedOffline(true);
        localStorage.setItem("ReceivedOffline", true);
        (getMessagesSuccess !== "idle" || messages?.length > 0) &&
          (() => {
            dispatch(addMessageToState(data?.lastMessage));
            audio?.play().catch((error) => {
              // Handle the error, if any
              console.error("Error playing audio:", error);
            });
          })();
      });
      addEvent("DM_USER", (data) => {
        setFetched(true);
        openChatBox();
        dispatch(addMessageToState(data));
        removeCookiesByName(`conversationId${window.WEBSITE_ID}`);
        const domain =
          "." + window.location.hostname.split(".").slice(-2).join(".");
        cookies.set(
          `conversationId${window.WEBSITE_ID}`,
          data?.conversation?._id,
          {
            path: "/",
            maxAge: 31536000,
            domain: domain,
          }
        );
        dispatch(getConversation(data?.conversation?._id));
        setConversationId(data?.conversation?._id);

        emitEvent("SET_CONVERSATION", {
          conversation: data?.conversation,
          id: window.$takiChat[0].id,
          webSiteId: window.WEBSITE_ID,
        });
        if (!open) {
          setReceivedOffline(true);
          localStorage.setItem("ReceivedOffline", true);
        }
      });
      setAwayTimer(newAwayTimer);
      // RESOLVED_FROM_OPERATOR
      addEvent("RESOLVED_FROM_OPERATOR", () => {
        setResolved(true);
      });
    }
  }, [open, assignedOperator, messages, getMessagesLoading]);

  const sendUserMeta = (obj) => {
    setIsLoading(false);
    d.id = fakeId;
    dispatch(
      sendMeta({
        meta: d,
        conversation: conversationId,
        question: obj?.question || undefined,
      })
    );
  };

  // OPEN_CHAT_BOX
  const openChatBox = () => {
    setIsOpen(true);
    !fetched && setFetched(true);
    if (window.WEBSITE_ID && connectedOperators.length === 0) {
      dispatch(getAllOperators());
    }
    if (conversationId) {
      //Get previous messages
      setTodayDate(true);
      if (!fetched) {
        fetchAllMessages();
        setFetched(true);
      }
      if (messages && messages?.length >= 1) {
        if (
          messages[messages?.length - 1]?.from &&
          messages[messages?.length - 1]?.from === "OPERATOR"
        ) {
          if (messages[messages?.length - 1]?.seenByClient === false) {
            dispatch(seenMessageClient(messages[messages?.length - 1]));
            dispatch(
              SeenByClient({
                message: messages[messages?.length - 1]?._id,
                conversation: conversationId,
              })
            );
            emitEvent("SEEN_FROM_CLIENT", {
              conversation: conversationId,
              webSiteId: window.WEBSITE_ID,
              _id: messages[messages?.length - 1]?._id,
            });
          }
        }
      }
    } else {
      if (loaded) {
        bot({ messages: [] }, null, null, true);
        setLoaded(false)
      }

    }
    if (isError) {
      console.log(errorMessage);
    }
    // if (conversationId)
    //   emitEvent("CONNECT", {
    //     webSiteId: window.WEBSITE_ID,
    //     SOCKET_ID: socket?.id,
    //     conversationId,
    //   });
    dispatch(reset());
  };
  useEffect(() => {
    let filledInput = false;
    let timer;
    text !== "" && (filledInput = true);
    !isTypingEvent &&
      filledInput &&
      (() => {
        emitEvent("IS_TYPING", {
          webSiteId: window.WEBSITE_ID,
          conversationId,
          from: "CLIENT",
          isTyping: true,
        });
        setIsTypingEvent(true);
      })();
    filledInput &&
      (timer = setTimeout(function () {
        emitEvent("IS_TYPING", {
          webSiteId: window.WEBSITE_ID,
          conversationId,
          from: "CLIENT",
          isTyping: false,
        });
        setIsTypingEvent(false);
      }, 20000));
    isTypingEvent &&
      !filledInput &&
      (timer = setTimeout(function () {
        emitEvent("IS_TYPING", {
          webSiteId: window.WEBSITE_ID,
          conversationId,
          from: "CLIENT",
          isTyping: false,
        });
        setIsTypingEvent(false);
      }, 20000));
    return () => {
      clearTimeout(timer);
    };
  }, [text]); // ON_CHANGE_HANDLER
  const onChange = (e) => {
    setText(e.target.value);
  };
  //EMOJI
  const onEmojiClick = (event) => {
    setText((prevInput) => prevInput + event.emoji);
  };

  const bot = (work, e, check, newConversation = false) => {
    clearTimeout(botTimer);
    if (start) {
      // setStart(false);
    }
    if (isGoing) {
      if (check) {
        if (
          (e?.type === "click" &&
            e?.target?.previousElementSibling?.value?.trim()?.length === 0) ||
          isNotValid(e.target?.previousElementSibling?.value?.trim())
        ) {
          return;
        }
      }
      if (nextQuestion || nextQuestionChoice) {
        let choice =
          nextQuestionChoice && typeof nextQuestionChoice == "object" ? nextQuestionChoice?._id : nextQuestionChoice ||
            (typeof nextQuestion === "object" ? nextQuestion?._id : nextQuestion);
        if (choice && work) {
          const newDate = Date.now();
          localStorage.setItem("started-at", newDate);
          try {
            dispatch(getQuestionByChoice(choice)).then((res) => {
              if (res.error) {
                setIsGoingAgain(false);
                return;
              }
              if (
                res.payload?.next ||
                (res.payload?.choices.length > 0 && !res.error)
                && !res.payload?.draft
              ) {
                setIsLoading(true);
                setIsTyping(true);
                setTimeout(() => {
                  conversation && dispatch(
                    createMessageByBot({
                      question: res.payload?._id,
                      conversationId: cookies.get(`conversationId${window.WEBSITE_ID}`),
                    })
                  ).then((resp) => {
                    if (typeof resp?.payload === "object") {
                      emitEvent("MESSAGE_FROM_CLIENT", {
                        webSiteId: window?.WEBSITE_ID,
                        conversation: {
                          ...resp.payload,
                          lastMessage: {
                            ...resp.payload,
                            content: resp?.payload?.question?.content,
                            question: resp?.payload?.question,
                            value: d,
                          },
                          unread: 1,
                        },
                      });
                      dispatch(
                        addMessageToState({
                          ...res.payload,
                          from: "BOT",
                          createdAt: Date.now(),
                        })
                      );
                      setIsLoading(false);
                    }
                    if (res?.payload?.type === "message" && !res.payload?.draft) {
                      setSkip(true);
                    }
                    if (typeof res.payload?.next === "object" && !res.payload?.draft) {
                      setNextQuestion(res.payload?.next?._id);
                    } else {
                      if (!res.payload?.draft) {
                        setNextQuestion(res.payload?.next);
                      }
                    }
                    if (res.payload?.choices.length > 0 && !res.payload?.draft) {
                      const c = res?.payload?.choices?.filter((choice) => !choice?.draft);
                      setChoices(c);
                    }
                    setIsTyping(false);
                    setIsLoading(false);
                  });
                }, 2000);
                let val = res.payload?.next || res.payload?.choices.length > 0;
                setNextQuestionChoice(null);
                setNextQuestion(null);
                if (!res.payload?.draft) {
                  setIsGoingAgain(val);
                }
              } else {
                if (!res?.payload?.draft) {
                  setIsLoading(true);
                  const newBotTimer = setTimeout(() => {
                    dispatch(
                      createMessageByBot({
                        question: res.payload?._id,
                        conversationId: cookies.get(`conversationId${window.WEBSITE_ID}`),
                      })
                    ).then((resp) => {
                      if (typeof resp?.payload === "object") {
                        emitEvent("MESSAGE_FROM_CLIENT", {
                          webSiteId: window?.WEBSITE_ID,
                          conversation: {
                            ...resp.payload,
                            lastMessage: {
                              ...resp.payload,
                              content: resp?.payload?.question?.content,
                              question: resp?.payload?.question,
                              value: d,
                            },
                            unread: 1,
                          },
                        });
                        dispatch(
                          addMessageToState({
                            ...res.payload,
                            from: "BOT",
                            createdAt: Date.now(),
                          })
                        );
                        setIsLoading(false);
                        setUseAI(true)
                      }
                    });
                    if (typeof res.payload?.next === "object" && !res.payload?.draft) {
                      setNextQuestion(res.payload?.next?._id);
                    } else {
                      if (!res.payload?.draft) {
                        setNextQuestion(res.payload?.next);
                      }
                    }
                    setBotTimer(newBotTimer);
                    setIsTyping(false);
                    setIsLoading(false);
                  }, 2000);
                  setNextQuestion(null);
                  setNextQuestionChoice(null);
                  res?.payload?.displayMeta && sendUserMeta({ question: res?.payload?._id });
                }
              }
              setNextQuestionChoice(null);

              setChoices(null);
            });
          } catch (err) {
            console.log(err)
            document
              .querySelector(".chat-box-footer-textarea")
              ?.setAttribute("disabled", false);
            // (err) => {
            //   if (err) {
            //     sendUserMeta();
            //     return;
            //   }
            // };
            setIsTyping(false);
            setIsGoingAgain(false);
            setUseAI(true);

          }
        }
        setIsLoading(false);
        return;
      } else {
        try {
          if (counter > 0) {
            emitEvent("MESSAGE_FROM_CLIENT", {
              webSiteId: window?.WEBSITE_ID,
              conversation: conversationId,
              meta: d,
            });
            setIsLoading(false);
            setIsTyping(false);
            setUseAI(true);
            return;
          }
          const newDate = Date.now();
          localStorage.setItem("started-at", newDate);
          let currentOrder = order;
          if (currentOrder) {
            if (window.$takiChat?.length > 0) {
              currentOrder = 2;
            }
          }
          counter <= 0 &&
            dispatch(getQuestionByOrder({ currentOrder })).then((res) => {
              setCounter((prev) => prev + 1);
              if (res?.error) {
                setIsGoingAgain(false);
                setIsLoading(false);
                return;
              }
              if (work?.messages?.length > 0 && work?.messages[work?.messages?.length - 1]?.from == "BOT") {
                if (work?.messages[work?.messages?.length - 1]?._id == res?.payload?._id || (work?.messages[work?.messages?.length - 2]?.from == "BOT" && work?.messages[work?.messages?.length - 2]?.type == "message" && work?.messages[work?.messages?.length - 2]?._id == res?.payload?._id)) {
                  return;
                }
              }
              if (!res?.payload?.draft) {
                setIsLoading(true);
                setIsTyping(true);
                const newBotTimer = setTimeout(() => {
                  dispatch(
                    createMessageByBot({
                      question: res.payload?._id,
                      conversationId,
                      newConversation: !conversationId ? true : false
                    })
                  )
                    .then((resp) => {
                      if (!conversationId) {
                        const domain =
                          "." + window.location.hostname.split(".").slice(-2).join(".");
                        removeCookiesByName(`conversationId${window.WEBSITE_ID}`);
                        cookies.set(
                          `conversationId${window.WEBSITE_ID}`,
                          typeof resp.payload?.conversation == "object" ? resp?.payload?.conversation?._id : resp?.payload?.conversation,
                          {
                            path: "/",
                            maxAge: 31536000,
                            domain: domain,
                          }
                        );
                        dispatch(createSession({
                          conversation: typeof resp.payload?.conversation == "object" ? resp?.payload?.conversation?._id : resp?.payload?.conversation,
                          startedAt: new Date(Date.now()),
                          endedAt: new Date(Date.now())
                        })).then((res) => {
                          if (res?.payload?._id) {
                            setSession(res?.payload?._id)
                          }
                        })
                        if (typeof resp?.payload?.conversation === "object") {
                          dispatch(setConversation(resp?.payload.conversation?._id))
                          setConversationId(resp?.payload?.conversation?._id)
                        }
                        if (typeof resp?.payload?.conversation === "string") {
                          setConversationId(resp?.payload?.conversation)
                        }
                        emitEvent('CONNECT', {
                          webSiteId: window.WEBSITE_ID,
                          SOCKET_ID: socket?.id,
                          conversationId: typeof resp.payload?.conversation == "object" ? resp?.payload?.conversation?._id : resp?.payload?.conversation
                        })
                      }

                      if (typeof resp?.payload === "object") {
                        let conv = typeof resp?.payload.conversation == "object" ? resp?.payload?.conversation : conversation
                        emitEvent("MESSAGE_FROM_CLIENT", {
                          webSiteId: window?.WEBSITE_ID,
                          conversation: {
                            ...conv,
                            lastMessage: {
                              conversation: typeof resp?.payload?.conversation == "object" ? resp?.payload?.conversation?._id : resp?.payload?.conversation,
                              ...resp.payload?.message || { ...resp?.payload?.question },
                              content: resp?.payload?.message?.question?.content || resp?.payload?.question?.content,
                              question: resp?.payload?.message?.question || resp?.payload?.question,
                              value: d,
                            },
                            unread: 1,
                          },
                        });
                        dispatch(createSession({
                          conversation: typeof resp.payload?.conversation == "object" ? resp?.payload?.conversation?._id : resp?.payload?.conversation,
                          startedAt: new Date(Date.now()),
                          endedAt: new Date(Date.now())
                        })).then((res) => {
                          if (res?.payload?._id) {
                            setSession(res?.payload?._id)
                          }
                        })
                      }
                      setIsTyping(false);
                      dispatch(
                        addMessageToState({
                          ...res.payload,
                          from: "BOT",
                          createdAt: Date.now(),
                        })
                      );
                      if (res.payload?.next && !res.payload?.draft) {
                        setNextQuestion(res.payload?.next);
                      }
                      if (res.payload?.choices.length > 0 && !res.payload?.draft) {
                        const c = res?.payload?.choices?.filter((choice) => !choice?.draft);
                        setChoices(c);
                      }
                      setIsLoading(false);
                    })
                    .catch((err) => {
                      setUseAI(true);
                      setIsLoading(false);
                    });
                  setBotTimer(newBotTimer);
                }, 1500);
                if (res?.payload?.type === "message" && !res.payload?.draft) {
                  let q;
                  if (res?.payload?.choices?.length > 0) {
                    if (typeof res.payload?.next === "object") {
                      q = res.payload?.next?._id;
                    } else {
                      q = res.payload?.next;
                    }
                    setIsLoading(true);
                    setIsTyping(true);
                    setNextQuestionChoice(q);
                    setSkip(true);
                    return;
                  }
                  if (res?.payload?.next && !res.payload?.draft) {
                    if (typeof res.payload?.next === "object") {
                      q = res.payload?.next?._id;
                    } else {
                      q = res.payload?.next;
                    }
                    setNextQuestion(q);
                    setSkip(true);
                    setIsLoading(true);
                    setIsTyping(true);
                    return;
                  }
                  return;
                }
              }
            });
        } catch (err) {
          setIsLoading(false);
          setIsTyping(false);
          setIsGoingAgain(false);
          setUseAI(true);
        }
        setIsLoading(false);
      }
    }
  };

  const executeNext = (data) => {
    setIsLoading(true);
    setIsTyping(true);
    bot(data?.data?.work, null, false);
  };


  const audioRecorder = useAudioRecorder();
  const [audioResult, setAudioResult] = useState(false);
  const [audioRecordFile, setAudioRecordFile] = useState(null);

  useEffect(() => {
    if (audioRecordFile) setAudioResult(true)
  }, [audioRecordFile])
  // SEND_MESSAGE_HANDLER
  const sendHandler = async (e, type, action, data, check, msg) => {
    e.preventDefault();
    if (sent) {
      return;
    }
    !reached && setReached(true)
    let banned = false;

    if (text.trim() !== "" || getFile || data || audioRecordFile) {

      setText('')
      sessionId && dispatch(updateSession({
        sessionId: sessionId,
        endedAt: new Date(Date.now())
      }))
      let fakeId = nanoid(5);
      if (!cookies.get(`conversationId${window.WEBSITE_ID}`)) {
        document
          .querySelector(".chat-box-footer-textarea")
          ?.setAttribute("disabled", true);
        document
          .querySelector(".chat-box-footer-icons")
          ?.classList.add("no-events");
        // Create  a conversation  and Send the message
        if (type === "addmessage") {
          if (text.trim() !== "" || getFile || audioRecordFile) {
            if (start && !banned) {
              !isTranslated && dispatch(translate({
                content: text
              })).then((res) => {
                setIsTranslated(true)
                res?.payload?.status == 200 && setLanguage(res?.payload?.data?.language?.split('_')[0])
              })
            }
            dispatch(
              addMessageToState({
                content: text.trim().length > 0 ? text : undefined,
                file: audioRecordFile
                  ? URL.createObjectURL(audioRecordFile)
                  : getFile
                    ? URL.createObjectURL(getFile)
                    : null,
                filetype: audioRecordFile
                  ? audioRecordFile.type
                  : getFile
                    ? getFile?.type
                    : null,
                createdAt: Date.now(),
                from: "CLIENT",
                fakeId,
              })
            );
            if (audioRecordFile) {
              dispatch(transcribe(audioRecordFile)).then((res) => {
                if (res?.payload?.data?.answer) {
                  dispatch(addMessageToState({
                    from: "AI",
                    content: res?.payload?.data?.answer?.answer,
                    _id: nanoid(),
                    createdAt: Date.now(),
                    fakeId
                  }))
                }
              })
            }

            audioRecordFile && setAudioResult(false);
          }
        }
        if (action === "dispatch") {
          if (
            check &&
            e?.target?.previousElementSibling?.value?.trim()?.length === 0
          ) {
            return;
          }
          let key = data?.msg?.label || data?.el?.content;
          let val = data?.userData;
          document
            ?.getElementById(data?.el?._id)
            .setAttribute("disabled", true);
          document
            ?.getElementById(`${data?.el?._id}btn`)
            .setAttribute("disabled", true);
          d[key] = val;
          data?.el?.displayMeta && sendUserMeta({ question: data?.el?._id });
          setUserData("");
        }
        // Send User Information IF Script Exist
        if (window.$takiChat?.length > 0) {
          const meta = window.$takiChat[0];
          setSent(true);
          if (start && !banned) {
            !isTranslated && dispatch(translate({
              content: text
            })).then((res) => {
              setIsTranslated(true)
              res?.payload?.status == 200 && setLanguage(res?.payload?.data?.language?.split('_')[0])
            })
          }
          audioRecordFile && setAudioResult(false);
          await dispatch(
            createMessage({
              conversationData: conversation,
              content: text.trim().length > 0 ? text : undefined,
              file: audioRecordFile
                ? audioRecordFile
                : getFile
                  ? getFile
                  : undefined,
              meta,
              fakeId,
            })
          ).then((res) => {
            if (res?.payload?.message?.includes("banned")) {
              setIsGoingAgain(false);
              banned = true;
              return;
            }
            if (
              res.meta?.requestStatus === "fulfilled"
            ) {
              setConversationId(res.payload?.newMessage?.conversation?._id);
            }
            if (
              !res.error &&
              cookies.get(`conversationId${window.WEBSITE_ID}`)
            ) {
              document
                .querySelector(".chat-box-footer-textarea")
                .removeAttribute("disabled");
              document
                .querySelector(".chat-box-footer-icons")
                ?.classList.remove("no-events");
            }
            if (!res.error) {
              setIsSuccess(true);
            }
            if (res.error) {
              setIsGoingAgain(false);
              setIsTyping(false);
              setIsLoading(false);
              return;
            }
            setSent(false);
          });
        } else {
          if (type === "addmessage") {
            setSent(true);
            if (start && !banned) {
              !audioRecordFile && !isTranslated && dispatch(translate({
                content: text
              })).then((res) => {
                setIsTranslated(true)
                res?.payload?.status == 200 && setLanguage(res?.payload?.data?.language?.split('_')[0])
              }
              )
            }
            audioRecordFile && setAudioResult(false);
            await dispatch(
              createMessage({
                conversationData: conversation,
                content: text.trim().length > 0 ? text : undefined,
                file: audioRecordFile
                  ? audioRecordFile
                  : getFile
                    ? getFile
                    : undefined,
                fakeId,
              })
            ).then((res) => {
              if (res?.payload?.message?.includes("banned")) {
                setIsGoingAgain(false);
                banned = true;
                return;
              }
              if (
                res.meta?.requestStatus === "fulfilled"
              ) {
                setConversationId(res.payload?.newMessage?.conversation?._id);
              }
              if (
                !res.error &&
                cookies.get(`conversationId${window.WEBSITE_ID}`)
              ) {
                document
                  .querySelector(".chat-box-footer-textarea")
                  .removeAttribute("disabled");
                document
                  .querySelector(".chat-box-footer-icons")
                  ?.classList.remove("no-events");
              }
              if (!res.error) {
                setIsSuccess(true);
              }
              if (res.error) {
                setIsGoingAgain(false);
                setIsTyping(false);
                setIsLoading(false);
              }
              setSent(false);
            });
            if (audioRecordFile) {
              dispatch(transcribe(audioRecordFile)).then((res) => {
                if (res?.payload?.data?.answer) {
                  dispatch(addMessageToState({
                    from: "AI",
                    content: res?.payload?.data?.answer?.answer,
                    _id: nanoid(),
                    createdAt: Date.now(),
                    fakeId
                  }))
                }
              })

            }
            audioRecordFile && setAudioRecordFile(null)
          }
        }
        if (action == "dispatch") {
          if (data?.msg?.next) {
            setNextQuestionChoice(data?.msg?.next)
            setIsTyping(true)
            setIsLoading(true)
            bot({
              work: true
            })
          }
        }
        setTodayDate(true);
        setText("");
        setGetFile(null);
      } else {
        if (type === "addmessage") {
          if (start && !banned) {
            !audioRecordFile && !isTranslated && dispatch(translate({
              content: text
            })).then((res) => {
              setIsTranslated(true)
              res?.payload?.status == 200 && setLanguage(res?.payload?.data?.language?.split('_')[0])
            })
          }
          dispatch(
            addMessageToState({
              content: text.trim().length > 0 ? text : undefined,
              createdAt: Date.now(),
              from: "CLIENT",
              conversation: conversationId,
              fakeId,
              seenBy: [],
              file: audioRecordFile
                ? URL.createObjectURL(audioRecordFile)
                : getFile
                  ? URL.createObjectURL(getFile)
                  : null,
              filetype: audioRecordFile
                ? audioRecordFile.type
                : getFile
                  ? getFile?.type
                  : null,
            })
          );
          audioRecordFile && setAudioResult(false);
        }
        if (action === "dispatch") {
          if (
            (check &&
              e &&
              e?.type === "click" &&
              e?.target?.previousElementSibling?.value?.trim()?.length === 0) ||
            isNotValid(e.target?.previousElementSibling?.value?.trim())
          ) {
            return;
          }
          let key = data?.msg?.label || data?.msg?.content;
          let val = data?.userData;
          document
            ?.getElementById(data?.msg?._id)
            ?.setAttribute("disabled", true);
          document
            ?.getElementById(`${data?.msg?._id}btn`)
            ?.setAttribute("disabled", true);
          d[key] = val;
          data?.msg?.displayMeta && sendUserMeta({ question: data?.msg?._id });
          setUserData("");
        }
        if (window.$takiChat?.length > 0) {
          const meta = window.$takiChat[0];
          if (type === "addmessage") {
            setSent(true);
            if (start && !banned) {
              !isTranslated && dispatch(translate({
                content: text
              })).then((res) => {
                setIsTranslated(true)
                res?.payload?.status == 200 && setLanguage(res?.payload?.data?.language?.split('_')[0])
              })
            }
            audioRecordFile && setAudioResult(false);
            await dispatch(
              createMessage({
                conversationData: conversation,
                content: text.trim().length > 0 ? text : undefined,
                file: audioRecordFile
                  ? audioRecordFile
                  : getFile
                    ? getFile
                    : undefined,
                conversation: conversationId,
                fakeId,
                meta,
              })
            ).then((res) => {
              if (res?.payload?.message?.includes("banned")) {
                setIsGoingAgain(false);
                banned = true;
                return;
              }
              if (
                res.meta?.requestStatus === "fulfilled" &&
                res.payload?.newMessage?.conversation !==
                cookies.get(`conversationId${window.WEBSITE_ID}`)
              ) {
                removeCookiesByName(`conversationId${window.WEBSITE_ID}`);
                const domain =
                  "." + window.location.hostname.split(".").slice(-2).join(".");
                cookies.set(
                  `conversationId${window.WEBSITE_ID}`,
                  res?.payload?.newMessage?.conversation,
                  {
                    path: "/",
                    maxAge: 31536000,
                    domain: domain,
                  }
                );
              }
              if (typeof res?.payload?.newMessage?.conversation == "string") {
                if (
                  cookies.get(`conversationId${window.WEBSITE_ID}`) !=
                  res.payload?.newMessage?.conversation?.toString()
                ) {
                  setConversationId(res.payload?.newMessage?.conversation);
                }
              }

              !res.error &&
                dispatch(
                  setMsg({ msg: res.payload.newMessage, fakeId: fakeId })
                );
              if (!res.error) {
                setIsSuccess(true);
              }
              if (res.error) {
                setIsGoingAgain(false);
                setIsTyping(false);
                setIsLoading(false);
              }
              setSent(false);
            });
            if (audioRecordFile) {
              dispatch(transcribe(audioRecordFile)).then((res) => {
                if (res?.payload?.data?.answer) {
                  dispatch(addMessageToState({
                    from: "AI",
                    content: res?.payload?.data?.answer?.answer,
                    _id: nanoid(),
                    createdAt: Date.now(),
                    fakeId
                  }))
                }
              })

            }
          }
        } else {
          if (type === "addmessage") {
            setSent(true);
            audioRecordFile && setAudioResult(false);
            await dispatch(
              createMessage({
                conversationData: conversation,
                content: text.trim().length > 0 ? text : undefined,
                file: audioRecordFile
                  ? audioRecordFile
                  : getFile
                    ? getFile
                    : undefined,
                conversation: conversationId,
                fakeId,
              })
            ).then((res) => {
              if (res?.payload?.message?.includes("banned")) {
                setIsGoingAgain(false);
                banned = true;
                return;
              }
              if (
                res.meta?.arg.requestStatus === "fulfilled" &&
                res.payload?.newMessage?.conversation !==
                cookies.get(`conversationId${window.WEBSITE_ID}`)
              ) {
                removeCookiesByName(`conversationId${window.WEBSITE_ID}`);
                const domain =
                  "." + window.location.hostname.split(".").slice(-2).join(".");
                cookies.set(
                  `conversationId${window.WEBSITE_ID}`,
                  res?.payload?.newMessage?.conversation,
                  {
                    path: "/",
                    maxAge: 31536000,
                    domain: domain,
                  }
                );
              }
              if (typeof res?.payload?.newMessage?.conversation == "object") {
                if (
                  conversationId.toString() !=
                  res.payload?.newMessage?.conversation?._id?.toString()
                ) {
                  setConversationId(res.payload?.newMessage?.conversation);
                }
              }
              if (typeof res?.payload?.newMessage?.conversation == "string") {
                if (
                  conversationId.toString() !=
                  res.payload?.newMessage?.conversation?.toString()
                ) {
                  setConversationId(res.payload?.newMessage?.conversation);
                }
              }

              !res.error &&
                dispatch(
                  setMsg({ msg: res.payload.newMessage, fakeId: fakeId })
                );
              !res.error && setIsSuccess(true);
              if (res.error) {
                setIsGoingAgain(false);
                setIsTyping(false);
                setIsLoading(false);
              }
              setSent(false);
            });
            if (audioRecordFile) {
              dispatch(transcribe(audioRecordFile)).then((res) => {
                if (res?.payload?.data?.answer) {
                  dispatch(addMessageToState({
                    from: "AI",
                    content: res?.payload?.data?.answer?.answer,
                    _id: nanoid(),
                    createdAt: Date.now(),
                    fakeId
                  }))
                }
              })
            }
          }
        }
        if (action == "dispatch") {
          if (data?.msg?.next) {
            setNextQuestionChoice(data?.msg?.next)
            bot({
              work: true
            })
          }
        }
        setText("");
        setGetFile(null);
        setAudioRecordFile(null)
        setAudioResult(false)
      }
      isTypingEvent &&
        (() => {
          emitEvent("IS_TYPING", {
            webSiteId: window.WEBSITE_ID,
            conversationId,
            from: "CLIENT",
            isTyping: false,
          });
          setIsTypingEvent(false);
        })();
      if (consultAI && type == 'addmessage' && !audioRecordFile) {
        setIsTyping(true)
        dispatch(askAI({ text })).then((res) => {
          if (res?.payload?.no_data_found) {
            setPass((prev) => prev + 1);
          } else {
            setPass(0);
          }
          console.log(pass)
          if (res?.payload?.answer) {
            dispatch(
              createMessageByAI({
                content: res?.payload?.no_data_found && pass < 2 ? "Il semble que je n'ai pas saisi votre demande. Pourriez-vous l'expliquer autrement ?" : res?.payload?.answer,
                no_data_found: res?.payload?.no_data_found
              })
            ).then((resp) => {
              if (typeof resp?.payload === "object") {
                emitEvent("MESSAGE_FROM_CLIENT", {
                  webSiteId: window?.WEBSITE_ID,
                  conversation: {
                    ...conversation,
                    lastMessage: {
                      ...resp.payload,
                      content: resp?.payload?.content,
                      question: resp?.payload,
                      value: d,
                    },
                    unread: 1,
                  },
                });
                dispatch(addMessageToState({
                  from: "AI",
                  content: res?.payload?.no_data_found && pass < 2 ? "Il semble que je n'ai pas saisi votre demande. Pourriez-vous l'expliquer autrement ?" : res?.payload?.answer,
                  _id: nanoid(),
                  createdAt: Date.now(),
                  fakeId
                }))
                setIsLoading(false);
              }
              setIsTyping(false);
              setIsLoading(false);
              if (!transferAI) {
                dispatch(transfer({
                  conversation: cookies.get(`conversationId${window.WEBSITE_ID}`),
                  source: 'BOT',
                  target: 'AI'
                }))
                settransferAI(true)
              }
            });
            sessionId && dispatch(updateSession({
              sessionId: sessionId,
              endedAt: new Date(Date.now())
            }))
          }
        })
      }
      setTimeout(() => {
        document
          .querySelector(".infinite-scroll-component")
          ?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 300);

    }
  };
  const [loaded, setLoaded] = useState(true);

  const containerStyle = {
    "--dynamic-color": color,
  };
  useEffect(() => {
    if (nextQuestionChoice) {
      executeNext({
        data: {
          work: true,
        },
      });
    }
    if (skip) {
      executeNext({
        data: {
          work: true,
        },
      });
      setSkip(false);
    }
  }, [nextQuestionChoice, choices, skip]);

  function handleSelectChange(event, msg) {
    const selectedOption = event.target.value;
    handleclick(selectedOption, event, "select", msg);
  }
  const handleclick = (el, e, type, msg) => {
    let conv;
    if (type === "select") {
      conv = JSON.parse(el);
      d[msg?.label] = conv?.content;
      msg?.displayMeta && sendUserMeta({ question: msg });
      e.target.setAttribute("disabled", true);
      if (!el?.next && el?.choices?.length == 0) {
        setUseAI(true);
      }
    }
    if (type === "checkbox") {
      conv = el;
      let content;
      if (conv?.translations?.length > 0) {

        content = conv?.translations?.find((el) => el?.language?.includes(language))?.label;
      }
      d[msg?.label] = conv?.content;
      msg?.displayMeta && sendUserMeta({ question: msg });
      if (!el?.next && el?.choices?.length == 0) {
        setUseAI(true);
      }
    }
    setOpenCounter(0);
    if (
      ["select", "checkbox", "radio"]?.includes(type) &&
      msg?.assign &&
      conv?.department
    ) {
      dispatch(assignDepartment({ department: conv?.department?._id }));
    }
    const choices = document.getElementsByName(e.name);
    for (let i = 0, c; (c = choices[i]); i++) {
      c.disabled = true;
    }
    if (!conv?.draft) {
      if (conv?.next) {
        setNextQuestionChoice(conv?.next);
      }
      if (conv?.choices?.length > 0) {
        setNextQuestionChoice(conv?._id);
        const c = conv?.choices?.filter((choice) => !choice?.draft);
        setChoices(c);
      }
    }
  };
  function handleSelectChange(event, msg) {
    const selectedOption = event.target.value;
    handleclick(selectedOption, event, "select", msg);
  }

  const onEnterClick = (e) => {
    if (e.keyCode == 13 && e.shiftKey) {
      e.stopPropagation();
      e.preventDefault();
      document.getElementById("textarea-chatbox-footer").value =
        document.getElementById("textarea-chatbox-footer").value + "\r\n";
      return;
    }
    if (e.key === "Enter") {
      e.stopPropagation();
      sendHandler(e, "addmessage");
    }
  };

  // SEND_STATUS_WHEN_OPERATOR_RELOAD
  useEffect(() => {
    if (open && !reached) {
      if (openCounter < 3) {
        setOpenCounter((prev) => prev + 1)
      } else {
        setOpenCounter(0);
        setShowMsg(true)
        setReached(true)
      }
    }
    //DISCONNECT
    addEvent("disconnect", () => {
      console.log("Disconnected");
    });
    document
      .querySelector(".messages-container")
      ?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [open]);


  return (
    statusGetWebsite === "success" && (
      <div className="chatbox">
        <audio className="audio-element">
          <source src="https://takichat.s3.eu-west-3.amazonaws.com/Discord+Notification+Sound+Effect.mp3"></source>
        </audio>
        {open ? (
          <div className="client-chatbox" dir="auto">
            <div
              style={color && { backgroundColor: color }}
              className={`chat-box-header`}
            >
              <div className="chatbox-header-top">
                <div>
                  <div className="chat-box-header-title">
                    {"Questions ?"} {"Chat with us !"}
                  </div>
                  <div className="chat-box-header-desc">
                    {"Typically replies under an hour"}
                  </div>
                </div>
                <div className="header-buttons-container">
                  <IconButton color="primary" aria-label="help desk"
                    component="a"
                    href="https://postehelpdesk.messaggera.com/"
                    style={{ color: 'white' }}
                    target="_blank"
                  >

                    <HelpOutlineIcon style={{ color: 'white' }} />

                  </IconButton>
                  <IconButton
                    onClick={() => setIsOpen(false)}
                    className="close-btn-container"
                  >
                    <CloseOutlinedIcon className="close-btn-icon" />
                  </IconButton>
                </div>
              </div>

              <div className="employees-container">
                {connectedOperators.length > 2
                  ? connectedOperators?.slice(-3)?.map((operator, index) => {
                    return (
                      <Employee
                        key={index}
                        image={operator?.avatar}
                        name={operator.firstName}
                      />
                    );
                  })
                  : connectedOperators?.map((operator, index) => {
                    return (
                      <Employee
                        key={index}
                        image={operator.avatar}
                        name={operator.firstName}
                      />
                    );
                  })}
                {totalOperators ? (
                  <div className="employee-wrapper">
                    <div
                      style={color && { backgroundColor: color }}
                      className="employee"
                    >
                      {totalOperators}+
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <ToastContainer />
            {/* {!isSocketConnected && (
            <div
              className="reconnection-container"
              onClick={() => {
                socket.connect();
                dispatch(setConnectionSocket(true));
              }}
            >
              <div className="reconnection">
                Your Connection Was Interrupted. Try to reconnect
              </div>
            </div>
          )} */}
            <div className="chat-box-body">
              {todayDate && (
                <div className="today-date">
                  <div className="today-date-text">
                    {new Date().toLocaleDateString({
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    })}
                  </div>
                </div>
              )}
              {getMessagesLoading && (
                <div className="loading-infinite-scroll-container">
                  <CircularProgress
                    //  custom color
                    size={25}
                    thickness={4}
                    sx={{
                      color: color || "#5032fa",
                    }}
                  />
                </div>
              )}
              <div
                style={color ? { ...containerStyle } : null}
                className={`messages-container ${color && "colored"} `}
                id="scrollable-target"
              >
                <InfiniteScroll
                  dataLength={messages.length}
                  next={fetchAllMessages}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "25px 10px",
                    gap: "10px",
                  }}
                  inverse={true}
                  hasMore={page <= messagesTotalPages}
                  scrollableTarget="scrollable-target"
                >
                  {messages?.length > 0 &&
                    messages?.map((message, index) => {
                      let uq = message?._id;
                      if (
                        message?.from === "OPERATOR" ||
                        ["AI", "BOT"]?.includes(message?.from)
                      ) {
                        if (
                          messages[index - 1]?.from === "OPERATOR" &&
                          messages[index - 1]?.createdBy?._id ===
                          message?.createdBy?._id
                        ) {
                          sameMessage = true;
                        } else {
                          sameMessage = false;
                        }
                        return (
                          <Message
                            color={color}
                            type="OPERATOR"
                            language={language}
                            setUseAI={setUseAI}
                            openCounter={openCounter}
                            setShowMsg={setShowMsg}
                            showMsg={showMsg}
                            pass={pass}
                            setSatisfied={setSatisfied}
                            setConsultOperator={setConsultOperator}
                            consultOperator={consultOperator}
                            satisfied={satisfied}
                            AILastMessage={index === messages.length - 1}
                            useAI={useAI}
                            setConsultAI={setConsultAI}
                            image={
                              ["AI", "BOT"]?.includes(message?.from)
                                ? false
                                : message?.createdBy?.avatar
                            }
                            createdAt={message?.createdAt}
                            content={
                              message?.content || message?.question?.content
                            }
                            uniqueKey={
                              ["AI", "BOT"]?.includes(message?.from) ? uq + index : uq
                            }
                            status={message?.createdBy?.availability?.status}
                            sameMessage={sameMessage}
                            SvgIcon={message?.from === "BOT" ? BotIcon : message?.from === "AI" ? AIicon : false}
                            name={
                              message?.from === "BOT"
                                ? "BOT" : message?.from === "AI" ? "AI"
                                  : message?.createdBy?.firstName
                            }
                            file={message?.file}
                            edited={message?.edited ? true : false}
                            id={message?._id}
                            filetype={message?.filetype}
                            key={['AI', "BOT"]?.includes(message?.from) ? uq + index : uq}
                            msg={message}
                            sendHandler={sendHandler}
                            handleSelectChange={handleSelectChange}
                            handleclick={handleclick}
                            setUserData={setUserData}
                            setPass={setPass}
                            userData={userData}
                            myMeta={meta}
                          />
                        );
                      } else {
                        return (
                          <Message
                            language={language}
                            color={color}
                            type="CLIENT"
                            createdAt={message?.createdAt}
                            content={message?.content}
                            uniqueKey={uq}
                            status={status}
                            file={message?.file}
                            name={message?.createdBy?.firstName}
                            isSent={message?.isSent}
                            id={message?._id}
                            filetype={message?.filetype}
                            isSuccess={isSuccess}
                            key={uq}
                            errorMsg={errorMessage}
                          />
                        );
                      }
                    })}
                </InfiniteScroll>
              </div>
              {isTyping && (
                <div className="message-operator" key={nanoid(5)}>
                  <div className="message-content">
                    <Messaging
                      color={color || "#5032faf0"}
                      width="5px"
                      height="5px"
                      duration="0.5s"
                    />
                  </div>
                </div>
              )}
            </div>
            {resolved && (
              <RatingModal
                setResolved={setResolved}
                conversationId={conversationId}
                color={color}
              />
            )}
            {getFile && (
              <div className="file-container">
                <div className="file-name-container">
                  <div>
                    <ImageOutlinedIcon className="image-outlined-icon" />
                  </div>
                  <Typography className="file-name-text">
                    {getFile?.name}
                  </Typography>
                </div>
                <IconButton onClick={() => setGetFile(null)}>
                  {/* <img src={trashIcon} alt="delete-icon-image-upload" /> */}
                  <DeleteIcon style={{ color: "#ed3863" }} />
                </IconButton>
              </div>
            )}
            {!audioRecordFile ? (
              <div className="chat-box-footer">
                <div type="submit" className="chat-box-footer-form" style={{
                  ...startedRecording ? { padding: "14px", display: "flex", justifyContent: "space-between" } : {}
                }}>
                  {startedRecording ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div className="record-fade-btn">
                        <RadioButtonCheckedIcon style={{ color }} />
                      </div>
                      {formatTime(seconds)}
                    </div>
                  ) : <textarea
                    id="textarea-chatbox-footer"
                    type="text"
                    placeholder={"Compose your message..."}
                    style={color ? { ...containerStyle } : null}
                    className={`chat-box-footer-textarea ${color && "colored"
                      } `}
                    onChange={onChange}
                    value={text}
                    onPaste={handlePasteImage}
                    onKeyDown={onEnterClick}
                    disabled={loading}
                  ></textarea>}
                  <div
                    style={color ? { ...containerStyle } : null}
                    className={`chat-box-footer-icons ${color && "colored-images"
                      }`}
                    dir="auto"
                  >
                    {!startedRecording && <div
                      className={
                        resolved ? "ev-none" : "chat-box-footer-icons-emoji"
                      }
                    >
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <React.Fragment>
                            <SmileIcon
                              className={`${color && "colored"}`}
                              {...bindTrigger(popupState)}
                            />
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <EmojiPicker
                                onEmojiClick={onEmojiClick}
                                categories={[
                                  {
                                    name: "Smiles & Emotions",
                                    category: Categories.SMILEYS_PEOPLE,
                                  },
                                ]}
                              />
                            </Popover>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </div>}
                    {text === "" && fileLoading ? (
                      <Box>
                        <CircularProgress
                          //  custom color
                          size={25}
                          thickness={4}
                          sx={{
                            color: "#5032fa",
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        {!startedRecording && <Upload
                          dispatch={dispatch}
                          createMessage={createMessage}
                          setGetFile={setGetFile}
                          conversationId={conversationId}
                          setIsSuccess={setIsSuccess}
                          fakeId={fakeId}
                          disabled={loading}
                          color={color}
                        />}
                        <AudioRecorder
                          audioRecorder={audioRecorder}
                          audioResult={audioResult}
                          color={color}
                          setAudioRecordFile={setAudioRecordFile}
                          isRecording={isRecording}
                          setStartedRecording={setStartedRecording}
                          audioRecordFile={audioRecordFile}
                        />
                        <span
                          className="send-button"
                          style={color && { backgroundColor: color }}
                          onClick={(e) => sendHandler(e, "addmessage")}
                        >
                          <img src={SendIcon} alt="send-icon" />
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="chat-box-footer">
                <div
                  type="submit"
                  className="chat-box-footer-form"
                  style={{
                    justifyContent: "space-between",
                    padding:
                      isRecording
                        ? "5px 14px"
                        : "0 14px",
                  }}
                >
                  {audioRecordFile &&
                    <div className="audio-container">
                      <audio
                        controls
                        src={URL.createObjectURL(audioRecordFile)}
                        style={{ width: "280px", height: "40px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          padding: "5px 0",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setAudioRecordFile(null);
                            setAudioResult(false)
                          }}
                        >
                          <CloseOutlinedIcon className="close-btn-icon" />
                        </IconButton>

                      </div>
                    </div>
                  }
                  <div
                    style={color ? { ...containerStyle } : null}
                    className={`chat-box-footer-icons ${color && "colored-images"
                      }`}
                    dir="auto"
                  >
                    <AudioRecorder
                      audioRecorder={audioRecorder}
                      audioResult={audioResult}
                      color={color}
                      setStartedRecording={setStartedRecording}
                    />
                    {audioRecordFile && (
                      <span
                        className="send-button"
                        style={color && { backgroundColor: color }}
                        onClick={(e) => sendHandler(e, "addmessage")}
                      >
                        <img src={SendIcon} alt="send-icon-2" />

                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : resolved ? (
          <div
            className="chat-circle"
            onClick={openChatBox}
            style={color && { background: color }}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              status={"red"}
            >
              <img
                className="chatbox-logo"
                src={ChatboxLogo}
                alt="messageralogo"
              />
            </StyledBadge>
          </div>
        ) : (
          <div
            className="chat-circle"
            onClick={openChatBox}
            style={color && { background: color }}
          >
            <img
              className="chatbox-logo"
              src={ChatboxLogo}
              alt="messageralogo"
            />
          </div>
        )}
        {receivedOffline && (
          <div
            className="chat-circle"
            onClick={openChatBox}
            style={color && { background: color }}
          >
            <StyledBadge
              overlap="circular"
              badgeContent={"!"}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              status={"red"}
            >
              <img
                className="chatbox-logo"
                src={ChatboxLogo}
                alt="messageralogo"
              />
            </StyledBadge>
          </div>
        )}
      </div>
    )
  );
};
export default ClientChat;
